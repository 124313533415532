import React from 'react'
import './Footer.scss';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className='footer'>
        <div className='conatiner'>
          <div className='footer-wrap'>
          <h6><span className='copy-right'>&copy;</span> Copyright 2025 Tres Health, Inc.</h6>
            <div className="footer-link-wrap">
              {localStorage.getItem("accessToken") && <Link target='_blank' to="/contact-us">Contact us</Link>}
              <Link to="/about-us">About us</Link>
              <Link to="/terms-and-conditions">Terms & conditions</Link>
              <Link to="/privacy-policy">Privacy policy</Link>
            </div>
            
          </div>
        </div>
        
    </footer>
  )
}

export default Footer
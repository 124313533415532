import React from "react";

const Facility = ({ provider }) => {
  return (
    <div>
      <div className="overview-basic-details-data">
        <h2>Basic Details</h2>
        {provider?.Provider?.ProviderIdentifier?.AlternateIdentifier?.AlternateIDNumber && <div className="ovev-details-data">
          <span>Provider ID</span>
          <span>{provider?.Provider?.ProviderIdentifier?.AlternateIdentifier?.AlternateIDNumber}</span>
        </div>}
        {provider?.Provider?.ProviderDetails?.Facility?.NPI2 && <div className="ovev-details-data">
          <span>NPI</span>
          <span>{provider?.Provider?.ProviderDetails?.Facility?.NPI2}</span>
        </div>}
        {provider?.Provider?.OfficeList?.Office?.[0]?.OfficeIdentifier?.OfficeID && <div className="ovev-details-data">
          <span>Office ID</span>
          <span>{provider?.Provider?.OfficeList?.Office?.[0]?.OfficeIdentifier?.OfficeID}</span>
        </div>}
        {provider?.Provider?.ProviderType && <div className="ovev-details-data">
          <span>Provider Type</span>
          <span>{provider?.Provider?.ProviderType}</span>
        </div>}
      </div>
      <div className="overview-basic-details-data">
        <h2>Facility Details</h2>
        <div className="ovev-details-data">
          <span>Facility Name</span>
          <span>{provider?.Provider?.ProviderDetails?.Facility?.FacilityName}</span>
        </div>
        {provider?.Provider?.ProviderDetails?.Facility?.BedCount ? <div className="ovev-details-data">
          <span>Bed Count</span>
          <span>{provider?.Provider?.ProviderDetails?.Facility?.BedCount}</span>
        </div>: null}
        {provider?.Provider?.ProviderDetails?.Facility?.FacilityTypeList?.FacilityType?.length > 0 && provider?.Provider?.ProviderDetails?.Facility?.FacilityTypeList?.FacilityType?.map((item)=>{
          return <React.Fragment key={item?.FacilityTypeName}>
            <div className="ovev-details-data">
              <span>Facility Type</span>
              <span>{item?.FacilityTypeName}</span>
            </div>
            <div className="ovev-details-data">
                  <span>Facility Sub Type</span>
                  <span>{item?.FacilitySubTypeList?.FacilitySubType?.map(d => d.FacilitySubTypeName).join(', ')}</span>
                </div>
          </React.Fragment>
        })}
      </div>
    </div>
  );
};

export default Facility;

import React, { useEffect, useState } from "react";
import "./ProviderSearch.scss";
import { ThreeDots } from "react-loader-spinner";
import Footer from "../../components/Footer/Footer";
import { useLocation, useNavigate } from "react-router-dom";

const ProviderSearch = ({handleFetchList, service, setService, location, setLocation}) => {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  const [serviceError, setServiceError] = useState(false);
  const [locationError, setLocationError] = useState(false);

  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const serviceQuery = query.get("service"); // Get 'service' query param
  const locationQuery = query.get("location"); // Get 'location' query param

  useEffect(()=>{
    if(serviceQuery){
      setService(serviceQuery);
    }
    if(locationQuery){
      setLocation(locationQuery);
    } else {
      // Get user's location using browser's geolocation API
      if ("geolocation" in navigator) {
        navigator.geolocation.getCurrentPosition(
          async (position) => {
            try {
              const response = await fetch(
                `https://nominatim.openstreetmap.org/reverse?format=json&lat=${position.coords.latitude}&lon=${position.coords.longitude}`,
                {
                  headers: {
                    'Accept-Language': 'en-US,en;q=0.9',
                    'User-Agent': 'IHP Customer App'
                  }
                }
              );
              const data = await response.json();
              const postcode = data.address?.postcode;
              if (postcode) {
                setLocation(postcode);
              }
            } catch (error) {
              console.error("Error fetching location details:", error);
            }
          },
          (error) => {
            console.error("Error getting location:", error);
          }
        );
      }
    }
  },[])

  const handleSubmit = (e) => {
    e.preventDefault();
    if(service?.length===0){
      setServiceError(true);
      return;
    }
    else if(location?.length===0){
      setLocationError(true);
      return;
    }
    handleFetchList();
    navigate(`?service=${service}&location=${location}`)
  };

  return (
    <>
      {isLoading ? (
        <ThreeDots
          visible={true}
          height="80"
          width="80"
          color="#240151"
          wrapperClass="loading-spin"
          radius="9"
          ariaLabel="three-dots-loading"
          wrapperStyle={{}}
        />
      ) : (
        <form onSubmit={handleSubmit}>
                  <div className="signin-wrp new-signin-wrp">
                    <div className="signin-form-wrp provider-search-in">
                      <h1 className="mbl-blck-searching">Know Before You Go</h1>
                      <p className="mbl-blck-searching">
                        Answer a few questions to find a right provider for you.
                      </p>
                      <div className="form-wrap provider-search-frm-wrp">
                        <div className="form-block provider-search-form-block">
                          <label
                            htmlFor="name"
                            className="form-label provider-search-label"
                          >
                            What Service are you looking?
                          </label>
                          <input
                            type="text"
                            onChange={(e)=>{setService(e.target.value); setServiceError(false)}}
                            value={service}
                            maxLength={100}
                            placeholder="Search by name, specialty, facility type..."
                            className="form-control contact-us-mail"
                          />
                          {serviceError && <p className="error">Please enter service</p>}
                        </div>
                        <div className="form-block provider-search-form-block">
                          <label
                            htmlFor="email"
                            className="form-label provider-search-label"
                          >
                            In which location are you searching for?
                          </label>
                          <input
                            type="text"
                            onChange={(e)=>{setLocation(e.target.value); setLocationError(false)}}
                            value={location}
                            maxLength={10}
                            placeholder="Search by zipcode"
                            className="form-control contact-us-mail"
                          />
                          {locationError && <p className="error">Please enter location</p>}
                        </div>
                      </div>
                    </div>
                    <div className="bottom-wrp submit-btn contact-us-btn listing-submit-btn-wrp">
                      <button className="btn" type="submit">
                        Search Providers
                        <i className="icon-arrow">
                          <svg
                            width="22"
                            height="22"
                            viewBox="0 0 33 33"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M19.9231 7.9575L20.0341 7.8288C20.4416 7.42147 21.0791 7.38449 21.5283 7.71786L21.657 7.82897L29.3017 15.4753C29.7088 15.8825 29.746 16.5195 29.4131 16.9687L29.3021 17.0974L21.6574 24.7527C21.2096 25.2011 20.483 25.2016 20.0346 24.7537C19.627 24.3466 19.5895 23.7092 19.9224 23.2597L20.0335 23.1309L25.7108 17.4438L4.78518 17.4452C4.20426 17.4452 3.72415 17.0136 3.64818 16.4534L3.6377 16.2977C3.6377 15.7169 4.06939 15.2368 4.62947 15.1608L4.78518 15.1502L25.7307 15.1488L20.034 9.4516C19.6267 9.04417 19.5897 8.40669 19.9231 7.9575Z"
                              fill="white"
                            />
                          </svg>
                        </i>
                      </button>
                    </div>
                  </div>
                </form>
      )}
    </>
  );
};

export default ProviderSearch;

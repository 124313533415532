import React from "react";

const Practitioner = ({ provider }) => {
  return (
    <div>
      <div className="overview-basic-details-data">
        <h2>Basic Details</h2>
        {provider?.Provider?.ProviderDetails?.Practitioner?.PersonalDetails?.Gender && (
          <div className="ovev-details-data">
            <span>Gender</span>
            <span>
              {provider?.Provider?.ProviderDetails?.Practitioner?.PersonalDetails?.Gender}
            </span>
          </div>
        )}
       {provider?.Provider?.ProviderIdentifier?.AlternateIdentifier?.AlternateIDNumber && <div className="ovev-details-data">
          <span>Provider ID</span>
          <span>{provider?.Provider?.ProviderIdentifier?.AlternateIdentifier?.AlternateIDNumber}</span>
        </div>}
        {provider?.Provider?.ProviderDetails?.Practitioner?.NPI1 && <div className="ovev-details-data">
          <span>NPI</span>
          <span>{provider?.Provider?.ProviderDetails?.Practitioner?.NPI1}</span>
        </div>}
        {provider?.Provider?.OfficeList?.Office?.[0]?.OfficeIdentifier?.OfficeID && <div className="ovev-details-data">
          <span>Office ID</span>
          <span>{provider?.Provider?.OfficeList?.Office?.[0]?.OfficeIdentifier?.OfficeID}</span>
        </div>}
        {provider?.Provider?.ProviderType && <div className="ovev-details-data">
          <span>Provider Type</span>
          <span>{provider?.Provider?.ProviderType}</span>
        </div>}
      </div>
      <div className="overview-basic-details-data">
        <h2>Practitioner Details</h2>
        <div className="ovev-details-data">
          <span>Practitioner Name</span>
          <span>{provider?.Provider?.ProviderDetails?.Practitioner?.PersonalDetails?.FirstName} {provider?.Provider?.ProviderDetails?.Practitioner?.PersonalDetails?.LastName}</span>
        </div>
        {/* <div className="ovev-details-data">
          <span>Bed Count</span>
          <span>{provider?.FacilityDetails}</span>
        </div>
        <div className="ovev-details-data">
          <span>Facility Type</span>
          <span>{provider?.FacilityDetails}</span>
        </div>
        <div className="ovev-details-data">
          <span>Facility Sub Type</span>
          <span>{provider?.FacilityDetails}</span>
        </div> */}
      </div>
    </div>
  );
};

export default Practitioner;
